import { defineStore } from "pinia";

import { useUserStore } from "./user";

import { getMenusByUserId } from "@/api/menu";
import { listToTree } from "@/utils/utils";

import Layout from "@/components/layout/BaseLayout";
import ParentView from "@/components/layout/ParentView.vue";

export const usePermissionStore = defineStore("permission", {
  state: () => ({
    menus: [],
    permissions: [],
    routes: [],
  }),
  getters: {
    headerMenus() {
      return this.menus;
    },
  },
  actions: {
    fetchMenus() {
      return new Promise((resolve) => {
        const userStore = useUserStore();
        userStore.getUserInfo().then(() => {
          console.log(userStore.userInfo);
          getMenusByUserId(userStore.userInfo.id).then((res) => {
            const list = res
              .filter(
                (item) =>
                  item.type == "DIR" ||
                  item.type == "MENU" ||
                  item.type == "LINK"
              )
              .map((item) => ({
                ...item,
                path: item.path.startsWith("/") ? item.path : `/${item.path}`,
                parentId: item.superior ? item.superior.id : null,
                meta: {
                  id: item.id,
                  pid: item.superiorId,
                },
              }));
            const newList = [];
            list.forEach((item) => {
              if (!item.superior && item.type == "MENU") {
                const newNode = {
                  id: item.id,
                  enabled: true,
                  sequence: 1,
                  createTime: Date.now(),
                  creator: null,
                  updateTime: Date.now(),
                  updater: null,
                  actionKey: "root:root",
                  componentPath: null,
                  icon: item.icon,
                  name: item.name,
                  path: "/",
                  redirect: item.path,
                  type: "DIR",
                  superior: null,
                };
                newList.push({
                  ...item,
                  superior: newNode,
                });
                newList.push({
                  ...newNode,
                });
              } else {
                newList.push(item);
              }
            });

            const buttonKeys = res
              .filter((item) => item.type == "BUTTON")
              .map((item) => item.actionKey);

            this.permissions = buttonKeys;

            newList.forEach((route) => {
              if (route.superior == null) {
                route.component = Layout;
              } else {
                if (route.type == "DIR" || route.componentPath == null) {
                  route.component = ParentView;
                } else {
                  route.component = () =>
                    import(`@/views/${route.componentPath}`);
                }
              }
            });

            // const routes = newList.toTree("superior", "children");
            const routes = listToTree(newList, "superiorId");
            console.log(routes);
            this.menus = routes;
            this.routes = newList;
            resolve({ list: newList, routes });
          });
        });
      });
    },
  },
});
