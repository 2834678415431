import request from "./index";
export function getAllOrganization(params) {
  return request({
    url: "/organization",
    method: "get",
    params,
  });
}

export function getList(params) {
  return request({
    url: `/organization`,
    method: "get",
    params,
  });
}

export function addItem(data) {
  return request({
    url: `/organization`,
    method: "post",
    data,
  });
}

export function updateItem(data) {
  return request({
    url: `/organization/${data.id}`,
    method: "patch",
    data,
  });
}

export function delItem(id) {
  return request({
    url: `/organization/${id}`,
    method: "delete",
  });
}
