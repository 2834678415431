<template>
  <header class="page-header">
    <div class="left flex">
      <img src="../../assets/images/header/logo-new.png" />
      <span class="title">主办管理后台</span>
      <div class="navs">
        <div
          class="nav"
          :class="{
            active: routePath && routePath.startsWith(nav.path),
          }"
          role="button"
          v-for="nav in headerMenus"
          :key="nav.id"
          @click="goTo(nav)"
        >
          <img class="icon" />
          <div class="lable">{{ nav.name }}</div>
        </div>
      </div>
    </div>
    <div class="header-right">
      <!-- <img src="../../assets/images/header/message.png" class="icon-message" /> -->
      <a-dropdown>
        <div class="profile" role="button">
          <img
            :src="userInfo?.portrait"
            class="icon-avatar"
            role="button"
            v-if="userInfo?.portrait"
          />
          <img
            src="../../assets/images/header/avatar.png"
            class="icon-avatar"
            role="button"
            v-else
          />
          <span class="username">{{ userInfo?.name }}</span>
          <DownOutlined style="color: #8791a3" />
        </div>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <a href="javascript:;" @click="logout">登出</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="linkToBasicInfo">基本信息</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="linkToEditPassword">修改密码</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </header>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "pinia";
import { Modal } from "ant-design-vue";
import { DownOutlined } from "@ant-design/icons-vue";
import { setToken } from "@/utils/auth";

import { useUserStore } from "@/stores/user";
import { usePermissionStore } from "@/stores/permission";
import mitt from "@/utils/mitt";

export default {
  data() {
    const dateLabel = dayjs().format(`dddd, MMM D, YYYY`);
    return {
      dateLabel,
      userName: "",
      headerMenus: [],
      routePath: null,
    };
  },
  components: {
    DownOutlined,
  },
  computed: {
    ...mapState(useUserStore, ["userInfo"]),
    // ...mapState(usePermissionStore, ["menus"]),
  },
  watch: {
    "$route.path": {
      handler(val) {
        this.routePath = val;
      },
      immediate: true,
    },
  },
  mounted() {
    this.getHeaderMenus();
    mitt.on("refreshHeaderMenus", () => {
      this.getHeaderMenus();
    });
    mitt.off("refreshHeaderMenus");
  },
  methods: {
    findFirstLeafNode(node) {
      // 检查节点是否有子节点
      if (!node.children || node.children.length === 0) {
        // 没有子节点，返回当前节点作为最底层的第一个子节点
        return node;
      }

      if (node.children[0].type == "BUTTON") {
        return node;
      }

      // 递归查找子节点中的最底层的第一个子节点
      return this.findFirstLeafNode(node.children[0]);
    },
    goTo(nav) {
      this.$router.push(this.findFirstLeafNode(nav).path);
    },
    getHeaderMenus() {
      const store = usePermissionStore();
      this.headerMenus = store.headerMenus;
    },
    goBack() {
      this.$router.go(-1);
    },
    linkToBasicInfo() {
      this.$router.push({
        path: "/profile/info",
      });
    },
    linkToEditPassword() {
      this.$router.push({
        path: "/profile/password",
      });
    },
    logout() {
      const store = useUserStore();
      const permissionStore = usePermissionStore();
      const _this = this;
      Modal.confirm({
        title: "退出",
        content: "确定退出系统吗？",
        onOk() {
          store.$reset();
          permissionStore.$reset();
          setToken("");
          _this.$router.push("/login");
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page-header {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 25px;
  justify-content: space-between;
  display: flex;
  background: #fff;
  position: relative;
  z-index: 100;
  box-shadow: 0px 0.5px 2px 0px rgba(0, 0, 0, 0.1);
  .left {
    flex: 0.8;
    align-items: center;
    .title {
      color: #1b1b25;
      font-family: PingFang SC;
      font-size: 18px;
      font-style: normal;
      width: 125px;
      padding-left: 17px;
      font-weight: 600;
      line-height: 140%; /* 25.2px */
    }

    .navs {
      margin-left: 66px;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 36px;
      .nav {
        display: flex;
        align-items: center;
        color: #374561;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        padding: 11px 8px;
        box-sizing: border-box;
        .label {
          margin-left: 10px;
        }
        &.active {
          color: #00a4f5;
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          border-radius: 6px;
          background: #f0f9fe;
        }
      }
    }
  }
  .header-right {
    color: #ffffff;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    .nav {
      margin-left: 12px;
      display: flex;
    }
    .date {
      padding-right: 13px;
      border-right: 1px solid #fff;
    }

    .text {
      line-height: 24px;
    }
    .icon {
      margin-right: 4px;
    }
  }
}

.important-tooltip {
  animation: imp 3s;
  animation-iteration-count: infinite;
}

.header-right {
  display: flex;
  align-items: center;
  .icon-message {
    width: 24px;
    height: 24px;
  }
  .profile {
    margin-left: 30px;
    display: flex;
    align-items: center;
    .username {
      margin: 0 8px;
      color: #8791a3;
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.icon-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
</style>

<style>
@keyframes imp {
  0% {
    color: red;
  }
  25% {
    color: rgb(0, 255, 38);
  }
  50% {
    color: yellow;
  }
  75% {
    color: rgb(201, 4, 109);
  }
  100% {
    color: khaki;
  }
}
</style>
