<template>
  <div class="page-panel">
    <div class="page-title">
      <div
        class="text"
        v-if="pageTitle && !hideTitle"
        style="display: flex; align-items: center"
      >
        <span
          :class="{ preTitle: pageTitle.split('/').length > 1 }"
          @click="toMenuPage(pageTitle, 0)"
          style="cursor: pointer"
          >{{ pageTitle.split("/")[0] }}</span
        >
        <span>{{
          pageTitle.split("/").length > 1 ? " / " + pageTitle.split("/")[1] : ""
        }}</span>
        <div style="margin-left: 8px">
          <slot name="filter"></slot>
        </div>
      </div>
      <div class="extra">
        <slot name="extra"> </slot>
        <FullscreenOutlined @click="handleFullScreen" v-if="showFullScreen" />
      </div>
    </div>
    <div class="table-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { useRoute, useRouter } from "vue-router";
import { FullscreenOutlined } from "@ant-design/icons-vue";
import hooks from "@/utils/hooks";
const { handleFullscreenElement } = hooks.useScreenfullEffect();
const route = useRoute();
const router = useRouter();
const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  showFullScreen: {
    // 是否显示全屏按钮
    type: Boolean,
    default: false,
  },
  hideTitle: {
    // 是否隐藏标题栏
    type: Boolean,
    default: false,
  },
});

const toMenuPage = (menuTitle, level) => {
  const routeName = menuTitle.split("/")[level];
  if (routeName === "展商管理") {
    router.push("/exhibition/trader"); //20231019 hebang 展商详情点击路由跳转到上级列表页
  } else if (routeName === "会刊管理") {
    router.push("/exhibition/journal");
  }
};

const handleFullScreen = () => {
  handleFullscreenElement(document.querySelector(".app-main"));
};
const pageTitle = props.title ?? ref(route.name);
</script>

<style lang="less" scoped>
.page-panel {
  padding: 16px 24px;
  background: #fff;
  .preTitle {
    font-size: 16px;
    font-weight: 400;
    color: #374561;
  }
  .page-title {
    display: flex;
    justify-content: space-between;
    .text {
      color: #374561;
      font-family: PingFang SC;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */
    }
    .extra {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      ::v-deep .anticon {
        cursor: pointer;
      }
    }
  }
  .table-wrapper {
    margin-top: 18px;
  }
}
</style>
