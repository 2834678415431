/* eslint-disable no-unused-vars */
import axios from "axios";
import { message } from "ant-design-vue";

import { useUserStore } from "@/stores/user";
import { setToken } from "@/utils/auth";

const MESSAGE_KEY = "messageKey";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 1000 * 60,
});

instance.interceptors.request.use((config) => {
  const exportUrls = [
    "/sms-tasks/exportExcel",
    "/email-tasks/task/file",
    "/sms-record/task/file",
    "/sms-tasks/task/file",
    "/file/downloadEqTemp",
    "/file/downloadEcTemp",
    "/file/downloadUserTemp",
    "/file/exportUser",
    "/file/batchDownloadFile",
    "/enquiry/export",
    "/publish/export",
    "/Information/export",
    "/proceeding-exhibitor/export",
  ];
  if (exportUrls.some((url) => config.url.indexOf(url) > -1)) {
    config.responseType = "blob";
  }
  const whiteUrlList = [
    "/authentication",
    "/authentication/phone-verification-code",
    "/authentication/email-verification-code",
  ];
  config.headers["Page-Url"] = window.location.pathname;
  const ip = sessionStorage.getItem("ip");
  if (ip) {
    config.headers["ip"] = ip;
  }
  if (
    whiteUrlList.some((url) => {
      return config.url == url;
    })
  ) {
    config.headers["X-API-KEY"] = 123456;
  } else {
    const store = useUserStore();
    config.headers.Authorization = `Bearer ${store.token}`;
    delete config.headers["X-API-KEY"];
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data);
  },
  (error) => {
    console.log(error);
    if (error && error.response) {
      if (error.response.status == 403) {
        message.error({
          content: "登录已过期，请重新登录",
          // content: "接口错误",
          duration: 2,
          key: MESSAGE_KEY,
        });
        // setTimeout(() => {
        //   const userStore = useUserStore();
        //   userStore.logout();
        //   setToken("");
        //   window.location.href = "/login";
        // }, 2000);
      } else {
        message.error({
          content: error.response?.data?.message || "接口错误",
          // content: "接口错误",
          duration: 2,
          key: MESSAGE_KEY,
        });
        // 微信未绑定，不允许登录
        if (error.response.data?.code == 5946) {
          setTimeout(() => {
            // const userStore = useUserStore()
            // userStore.logout()
            // setToken('')
            window.location.href = "/login";
          }, 2000);
        }
      }
    } else {
      if (error.code == "ERR_NETWORK") {
        message.error({
          key: MESSAGE_KEY,
          content: "ERR_NETWORK",
        });
      } else {
        message.error({
          key: MESSAGE_KEY,
          content: "接口连接异常",
        });
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
