<template>
  <div class="app-view">
    <page-header></page-header>
    <div class="main-container">
      <side-menu></side-menu>
      <div class="app-main">
        <router-view :key="path" class="content-view"></router-view>
      </div>
      <div class="sub-side-bar">
        <img
          @click="toOfficialWebsite"
          src="@/assets/images/guanfangwangzhan.png"
          alt=""
        />
        <a-popover placement="left">
          <template #content>
            <img
              style="
                width: 100px;
                height: 100px;
                background-color: lightgrey;
                margin-left: 8px;
              "
              src="@/assets/images/qr-code.png"
            />
            <h4>扫描二维码进入H5</h4>
          </template>
          <img src="@/assets/images/Rrl_s_067.png" alt="" />
        </a-popover>
        <a-popover placement="left">
          <template #content>
            <div style="width: 240px">
              <h3>联系我们</h3>
              <div class="info-item">
                <span class="label">地址：</span>
                <span>北京市丰台区顺三条21号嘉业大厦二期1号楼16层</span>
              </div>
              <div class="info-item">
                <span class="label">电话：</span>
                <span>010-67662159/2359/3089</span>
              </div>
              <div class="info-item">
                <span class="label">传真：</span>
                <span>010-67660809</span>
              </div>
              <div class="info-item">
                <span class="label">官网：</span>
                <span>www.china-bicycle.com</span>
              </div>
              <div class="info-item">
                <span class="label">邮箱：</span>
                <span>wzj@china-bicycle.com</span>
              </div>
            </div>
          </template>
          <img src="@/assets/images/kefu.png" alt="" />
        </a-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { useRoute } from "vue-router";

import PageHeader from "./PageHeader.vue";
import SideMenu from "./SideBar/SideMenu.vue";

export default {
  components: {
    PageHeader,
    SideMenu,
  },
  setup() {
    const route = useRoute();
    const path = ref("");

    watch(
      () => route.path,
      (val) => {
        path.value = val;
      },
      {
        immediate: true,
      }
    );
    const toOfficialWebsite = () => {
      window.open(process.env.VUE_APP_OFFICIAL_WEBSITE, "_blank");
    };
    return {
      path,
      toOfficialWebsite,
    };
  },
};
</script>

<style lang="less" scoped>
.sub-side-bar {
  width: 48px;
  height: 100%;
  border: 1px solid var(--yy-gray-color);
  position: fixed;
  top: 70px;
  right: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    margin: 24px 0;
    cursor: pointer;
  }
}
</style>
