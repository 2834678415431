<template>
  <a-popover
    title="表头字段设置"
    trigger="click"
    placement="left"
    v-model:visible="visible"
  >
    <template #content>
      <div class="set-wrapper">
        <a-checkbox-group
          v-model:value="checked"
          name="checkboxgroup"
          :options="checkOptions"
        />
      </div>
      <a-button type="primary" size="small" @click="handleConfirm"
        >确定</a-button
      >
      <a-button size="small" @click="handleReset">重置</a-button>
    </template>
    <SettingOutlined />
  </a-popover>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
import { SettingOutlined } from "@ant-design/icons-vue";
const props = defineProps({
  columns: {
    default: null,
  },
});
const checked = ref([]);
const checkOptions = ref([]);
const visible = ref(false);

const handleConfirm = () => {
  props.columns.forEach((p) => {
    p.show = false;
  });
  props.columns.forEach((p) => {
    if (checked.value.includes(p.dataIndex)) {
      p.show = true;
    }
  });
  visible.value = false;
};
const handleReset = () => {
  props.columns.forEach((p) => {
    p.show = true;
  });
  visible.value = false;
};
watch(
  props.columns,
  () => {
    checkOptions.value = props.columns
      .filter((x) => x.dataIndex)
      .map((p) => ({
        label: p.title,
        value: p.dataIndex,
      }));
    checked.value = props.columns.filter((p) => p.show).map((x) => x.dataIndex);
  },
  {
    immediate: true,
  }
);
</script>

<style lang="less" scoped>
.set-wrapper {
  width: 500px;
  ::v-deep {
    .ant-checkbox-wrapper {
      width: 30%;
    }
  }
  margin-bottom: 20px;
}
</style>
